import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeCa from "@angular/common/locales/ca";
import localeDe from "@angular/common/locales/de";
import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import localeIt from "@angular/common/locales/it";
import localeJa from "@angular/common/locales/ja";
import localeKo from "@angular/common/locales/ko";
import localePt from "@angular/common/locales/pt";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import localeZhchs from "@angular/common/locales/zh-Hans";
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { EmptyComponent } from './components/empty/empty.component';
import { FeedbackDialogComponent } from './components/feedback-dialog/feedback-dialog.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { HintsDialogComponent } from './components/hints-dialog/hints-dialog.component';
import { LearnYoutubeDialogComponent } from './components/learn-youtube-dialog/learn-youtube-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MessageEditDialogComponent } from './components/message-edit-dialog/message-edit-dialog.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { IIShapesBasicNiceTitleComponent } from './components/nice-title/nice-title.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { SaleDetailComponent } from './components/sale-detail/sale-detail.component';
import { SaleReceiptComponent } from './components/sale-receipt/sale-receipt.component';
import { SearchImageDialogComponent } from './components/search-image-dialog/search-image-dialog.component';
import { SubscriptionRequiredDialogComponent } from './components/subscription-required-dialog/subscription-required-dialog.component';
import { YesNoDialogComponent } from './components/yesno-dialog/yesno-dialog.component';
import { InjectDirective } from './directives/inject.directive';
import { AcronymPipe } from './pipes/acronym.pipe';
import { HexToColorNamePipe } from './pipes/hex-to-color-name.pipe';
import { IisCurrencyPipe } from './pipes/iis-currency.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { NgxDatePipe } from './pipes/ngx-date.pipe';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { ParseMessagePipe } from './pipes/parseMessage.pipe';
import { SlugifyPipe } from './pipes/sluggify.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { WithoutSanizaterHtmlPipe } from './pipes/without-sanizater-html.pipe';
import { AnalyticsService } from './services/analytics.service';
import { DeveloperService } from './services/developer.service';
import { HelpService } from './services/help.service';
import { ListsService } from './services/lists.service';
import { LoadingService } from './services/loading.service';
import { MessageService } from './services/message.service';
import { PreviousRouteService } from './services/previous-route.service';




registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeCa);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeJa);
registerLocaleData(localeEs);
registerLocaleData(localeKo);
registerLocaleData(localeZhchs);
registerLocaleData(localePt);


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule, MatDialogModule,
    MatProgressSpinnerModule,
    TranslateModule,
    FileUploadModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    MatIconModule,
    ClipboardModule,
    MatSlideToggleModule,
    MatSnackBarModule
  ],
  declarations:
    [
      IisCurrencyPipe,
      TruncatePipe,
      AcronymPipe,
      JoinPipe,
      SlugifyPipe,
      NgxDatePipe,
      HexToColorNamePipe,
      WithoutSanizaterHtmlPipe,
      ParseMessagePipe,
      OrderByDatePipe,
      InjectDirective,
      YesNoDialogComponent,
      ForbiddenComponent,
      SearchImageDialogComponent,
      NotfoundComponent,
      LoaderComponent,
      EmptyComponent,
      LearnYoutubeDialogComponent,
      NavbarComponent,
      FeedbackDialogComponent,
      SubscriptionRequiredDialogComponent,
      MessageEditDialogComponent,
      ProfileFormComponent,
      HintsDialogComponent,
      IIShapesBasicNiceTitleComponent,
      SaleDetailComponent,
      SaleReceiptComponent
    ],
  providers: [
    ListsService,
    HelpService,
    AnalyticsService,
    LoadingService,
    MessageService,
    DeveloperService,
    PreviousRouteService
  ],
  exports: [
    // Pipes  
    IisCurrencyPipe, TruncatePipe, AcronymPipe, HexToColorNamePipe, OrderByDatePipe, NgxDatePipe, InjectDirective, JoinPipe, SlugifyPipe, ParseMessagePipe, WithoutSanizaterHtmlPipe,
    // Components
    YesNoDialogComponent, ForbiddenComponent, NotfoundComponent, LoaderComponent,
    EmptyComponent, LearnYoutubeDialogComponent,
    NavbarComponent,
    FeedbackDialogComponent,
    SubscriptionRequiredDialogComponent,
    MessageEditDialogComponent,
    ProfileFormComponent, SaleDetailComponent, SaleReceiptComponent,
    SearchImageDialogComponent, HintsDialogComponent, IIShapesBasicNiceTitleComponent
  ]
})
export class BasicModule {

}
