<div class="subscriptionContainer">
  <div class="container">
      <div class="row">
          <div class="col-lg-6 offset-lg-3 offset-md-3 col-md-6  col-sm-8 offset-sm-2">
              <div class="logo">
                <img src="assets/img/logo_jewelry.svg"
                style=" display:block; margin-left:auto; margin-right:auto" width="250px"/>
              </div>
              <div class="titul">
                {{'MESSAGE_CONTROL.SUBSCRIPTION.USER' | translate:params}}                    
              </div>
          </div>
      </div>
  </div>
</div>