export class Owner {
    public id?: number;
    public fullname?: string;
    public avatar?: string;

    constructor() {

    }

    static create(jsonObject: any) {
        const o = new Owner();
        o.id = jsonObject.id ? jsonObject.id : -1;
        o.fullname = jsonObject.fullname ? jsonObject.fullname : null;
        o.avatar = jsonObject.avatar ? jsonObject.avatar : null;
        return o;
    }

}
