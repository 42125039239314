export class Label {
  public id: string;
  public name: string;
  public color: string;
  public path: string;
  public workspace: string;
  public standard: boolean;

  constructor(id: string, standard: boolean) {

    this.id = id;
    this.standard = standard;
  }

  static create(jsonObject: any): Label {
    const o = new Label(null, false);
    o.id = jsonObject.id ? jsonObject.id : null;
    o.name = jsonObject.name ? jsonObject.name : null;
    o.color = jsonObject.color ? jsonObject.color : null;
    o.path = jsonObject.path ? jsonObject.path : null;
    o.workspace = jsonObject.workspace ? jsonObject.workspace : null;
    o.standard = jsonObject.standard ? jsonObject.standard : false;
    return o;
  }

}
