import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-yesno-dialog',
  templateUrl: './yesno-dialog.component.html',
  styleUrls: ['./yesno-dialog.component.sass'],
  providers: [TranslatePipe],
  
})
export class YesNoDialogComponent implements OnInit {

  constructor(
    public thisDialogRef: MatDialogRef<YesNoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {

  }

  ngOnInit() {
    const values = Object.entries(this.data);
    values.map((v: any) => {

      this.translateService.get(v[1]).subscribe(res => {
        this.data[v[0]] = res;
      });
    });

  }

  onCloseConfirm() {
    this.thisDialogRef.close(true);
  }

  onCloseCancel() {
    this.thisDialogRef.close(false);
  }

}
