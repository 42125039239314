export class UserDesignSettings {

    dark_mode: boolean;
    gumball_snap_angle: number;
    gumball_snap_distance: number;
    gumball_snap_scale: number;

    viewer_background: boolean;
    viewer_background_color: string;
    viewer_logo: string;


    constructor() {

        this.dark_mode = false;
        this.gumball_snap_angle = 45;
        this.gumball_snap_distance = 1;
        this.gumball_snap_scale = 0.25;
        this.viewer_background = false;
        this.viewer_background_color = "#ffffff";
        this.viewer_logo = "NONE";
    }

    static create(jsonObject: any): UserDesignSettings {
        const o = new UserDesignSettings();

        o.dark_mode = jsonObject.dark_mode ? jsonObject.dark_mode : false;
        o.gumball_snap_angle = jsonObject.gumball_snap_angle ? jsonObject.gumball_snap_angle : 45;
        o.gumball_snap_distance = jsonObject.gumball_snap_distance ? jsonObject.gumball_snap_distance : 1.0;
        o.gumball_snap_scale = jsonObject.gumball_snap_scale ? jsonObject.gumball_snap_scale : 0.25;

        o.viewer_background = jsonObject.viewer_background ? jsonObject.viewer_background : false;
        o.viewer_background_color = jsonObject.viewer_background_color ? jsonObject.viewer_background_color : "#ffffff";
        o.viewer_logo = jsonObject.viewer_logo ? jsonObject.viewer_logo : "NONE";

        console.log('o ,', o);
        return o;
    }

}