import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class MessageService {

  duration = 3000;

  constructor(private snackBar: MatSnackBar, private translate: TranslateService, public router: Router) { }

  showErrorMessage(errorMessage: string, showUpdate: boolean = false) {
    this.translate.get(errorMessage).subscribe((res: string) => {

      if (showUpdate) {
        const s = this.snackBar.open(res, this.translate.instant("COMMON.UPGRADE"), {
          duration: 3000,
          panelClass: ['red-snackbar'],
        });
        s.onAction().subscribe(() => {
          this.router.navigate(['dashboard/billing/billing/'],
            { queryParams: { plan: 'yes' } });
        });
      }
      else {
        const s = this.snackBar.open(res, this.translate.instant("COMMON.HELP"), {
          duration: 3000,
          panelClass: ['red-snackbar'],
        });
        s.onAction().subscribe(() => {
          window.open('https://docs.2shapes.com', '_blank');
        });
      }


    });


  }

  showMessage(message: string, value = null) {
    this.translate.get(message, { valor: value }).subscribe(res => {
      this.snackBar.open(res, '', {
        duration: this.duration,
      });
    });
  }

  parseMessage(message: string, longMessage = false): string {

    let messageOut: string;

    if (message && message.startsWith('@')) {
      const toParse = message.split('#')[1];
      const valuesArray = toParse.split('_');

      const n_stock = valuesArray[0];
      const n_needed = valuesArray[1];
      const carats = valuesArray[2];
      const gemType = valuesArray[3];

      if (longMessage) {

        messageOut = `There are ${n_stock} of ${n_needed} ${gemType} with${carats} required`;
      } else {
        messageOut = `${n_needed} ${gemType} ${carats} Required`;
      }

    } else {

      messageOut = `ERRORS.BREAKDOWN.${message}`;
    }

    return messageOut;
  }


  showErrorMessageNicely(errorMessage: string) {

    const res = this.translate.instant('ERRORS.' + errorMessage);
    const snackBarRef = this.snackBar.open(res, 'Help', {
      duration: 3000,
      verticalPosition: 'bottom'
    });

    snackBarRef.onAction().subscribe(() => {
      window.open("https://docs.2shapes.com/2shapes-design/introduction", "_blank");
    });
  }


}
