import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Analytics } from '../models/analytics';


@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private urlAnalytics = environment.apiUrl + '/v1/store/business';
    private apikey = '';
    constructor(private http: HttpClient) {

    }

    public init(apikey: string) {
        this.apikey = apikey;
    }

    reportAnalytics(analytics: Analytics): Observable<any> {
        // Headers
        const headers = new HttpHeaders().append('apikey', this.apikey);
        // Parameters
        const info: { [id: string]: any } = {};

        if (analytics.ip) {
            info['ip'] = analytics.ip;
        }
        if (analytics.url) {
            info['url'] = analytics.url;
        }
        if (analytics.device) {
            info['device'] = analytics.device;
        }
        if (analytics.browser) {
            info['browser'] = analytics.browser;
        }
        if (analytics.os) {
            info['os'] = analytics.os;
        }
        const formData: FormData = new FormData();
        formData.append('data', JSON.stringify(info));
        return this.http.post(this.urlAnalytics, formData, { headers }).pipe(
            map(res => {
                return res;
            })
        );
    }

}
