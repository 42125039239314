import { Injectable } from '@angular/core';

import { LanguageService } from './language.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable()
export class PublicService {

  constructor(private http: HttpClient				) {
  }


  getCurrencyForWebPricing(): Observable<any> {

    return this.http.get("https://api.2shapes.com/v1/public/exchange").pipe(
      map((res: any) => {
        return <[]>res;
      })
    );


  }
}
