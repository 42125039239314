import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FingerSize, Region } from '../models/regions';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  regions: Region[];
  flags = [];

  region = 'USA';
  constructor(private http: HttpClient, private userService: UserService) {
    // Hauria de mirar la regio del User company
    this.region = this.userService.user.ringRegion;

    this.regions = [];

    this.getRegions().subscribe();
  }

  getRegions(): Observable<Region[]> {
    if (this.regions.length > 0) {
      return of(this.regions);
    }
    return this.http.get('assets/data/regions.json').pipe(
      map(res => {
        this.regions = [];
        const arr = <Region[]>res;
        arr.map(e => {
          this.regions.push(Region.create(e));
        });
        return this.regions;
      })
    );
  }

  getDefaultFingerSize(defaultFingerSize: string): Observable<FingerSize> {

    if (this.regions.length > 0) {
      const region = this.regions.find(r => r.id === defaultFingerSize);
      return of(region.sizes[region.default]);
    }

    return this.getRegions().pipe(map(() => {
      const region = this.regions.find(r => r.id === defaultFingerSize);
      return region.sizes[region.default];
    }));
  }
}
