import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Organization } from '../../models/organization';
import { UserService } from '../user.service';



@Injectable({
  providedIn: 'root'
})
export class ProfileOrganizationService {
  private urlEditOrganization = environment.apiUrl + '/v2/dashboard/organization';
  private urlGetOrganization = environment.apiUrl + '/v2/dashboard/organization';
  private urlSendTestEmail = environment.apiUrl + '/v2/dashboard/organization/emailtest';


  constructor(private http: HttpClient, private userService: UserService) { }

  public updateOrganization: Subject<Organization> = new Subject();

  getOrganization(): Observable<Organization> {
    const headers = new HttpHeaders().append('apikey', this.userService.user.apikey);
    return this.http.get(this.urlGetOrganization, { headers: headers }).pipe(
      map(res => {
        return Organization.create(res);
      })
    );
  }



  editOrganization(
    id: string,
    info: {
      [id: string]: any
    },
    logo?: string
  ): Observable<Organization> {


    const headers = new HttpHeaders().append('apikey', this.userService.user.apikey);
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(info));

    if (logo) {
      formData.append('logo', logo);
    }
    return this.http.post(this.urlEditOrganization + '/' + id, formData, { headers: headers }).pipe(
      map(res => {
        const org = Organization.create(res);
        this.userService.user.organization = org;
        return org;
      })
    );
  }

  sendTestEmail(): Observable<boolean> {
    const headers = new HttpHeaders().append('apikey', this.userService.user.apikey);
    return this.http.get(this.urlSendTestEmail, { headers: headers }).pipe(
      map(() => {
        return true;
      })
    );
  }

}
