import { User } from './user.interface';

export class LocalStorageTools {
	static containLocalUserToken(): boolean {
		const token = localStorage.getItem('2shapes_token');
		if (token == null) {
			return false;
		}
		const des = this.encode(token, 123);
		return (<string>des).split(';').length === 2;

	}

	static saveLocalStorage(user: User) {
		if (typeof user !== 'undefined') {
			const co = user.email + ';' + user.apikey;
			const token = this.encode(co, 123);
			localStorage.setItem('2shapes_token', token);
		}
	}

	static destroyLocalStorage() {
		localStorage.removeItem('2shapes_token');
	}


	static containLocalQuote(): boolean {
		const token = localStorage.getItem('2shapes_quote');
		if (token == null) {
			return false;
		}
		const des = this.encode(token, 123);
		return des != null;

	}

	static loadLocalQuote(): string | undefined {
		const token = localStorage.getItem('2shapes_quote');
		if (token == null) {
			return null;
		}
		const des = this.encode(token, 123);
		return des;

	}


	static saveLocalQuote(id: string) {
		if (typeof id !== 'undefined') {
			const token = this.encode(id, 123);
			localStorage.setItem('2shapes_quote', token);
		}
	}

	static destroyLocalQuote() {
		localStorage.removeItem('2shapes_quote');
	}

	// la mateixa funcio es per codificar i decodificar
	static encode(s: string, k: number) {
		let enc = '';
		// make sure that input is string
		const strLength: number = (<string>s).length;

		for (let i = 0; i < strLength; i++) {
			// create block
			const a = s.charCodeAt(i);
			// bitwise XOR
			const b = a ^ k;
			enc = enc + String.fromCharCode(b);
		}
		return enc;
	}
}
