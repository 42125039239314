import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HelpService {
    VideoAvailable: Subject<any> = new Subject();


    private llista: Video[] = [];


    routee;
    constructor(private router: Router, private http: HttpClient) {


        // llegim el json


        this.loadJson().subscribe(res => {
            this.llista = res;


        });


        this.routee = this.getActualRoute();
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {



                if ((this.llista.filter(t => t.route == val.url).length > 0)) {
                    this.VideoAvailable.next({
                        available: true,
                        url: this.llista.filter(t => t.route == val.url)[0].urlVideo
                    });
                } else {
                    this.VideoAvailable.next({
                        available: false,
                        url: ''
                    });
                }


            }
        });

    }




    getActualRoute() {
        return this.router.url;
    }

    loadJson(): Observable<Video[]> {
        return this.http.get('./assets/help.json').pipe(
            map((res: any) => {
                return <Video[]>res;
            })
        );
    }



}
class Video {
    public id: string;
    public urlVideo: string;
    public route: string;
}


