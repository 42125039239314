import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'iishapes-basic-learn-youtube-dialog',
  templateUrl: './learn-youtube-dialog.component.html',
  styleUrls: ['./learn-youtube-dialog.component.sass']
})
export class LearnYoutubeDialogComponent implements OnInit {
  safeURL: SafeResourceUrl;
  videoUrl = 'https://www.youtube.com/embed/';
  code;
  codeExist = false;
  constructor(private _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: string) {

    this.videoUrl = `https://www.youtube.com/embed/${data['videoid']}?rel=0&autoplay=1`;
  }

  ngOnInit(): void {
    this.codeExist = true;
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }

}
