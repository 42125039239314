import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iishapes-shared-notfound',
  templateUrl: 'notfound.component.html',
  styleUrls: ['notfound.component.sass']
})
export class IIShapesSharedNotfoundComponent implements OnInit {

  constructor(public router: Router,
              public translate: TranslateService) {


  }

  ngOnInit() {
  }

  goBack() {
    this.router.navigate(['/']);
  }

}
