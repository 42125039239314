export class Partner {
  public contact_email: string;
  public contact_name: string;
  public contact_phone: string;
  public name: string;
  public website: string;

  static create(jsonObject: any) {
    const o = new Partner();
    o.contact_email = jsonObject.contact_email ? jsonObject.contact_email : null;
    o.contact_name = jsonObject.contact_name ? jsonObject.contact_name : null;
    o.contact_phone = jsonObject.contact_phone ? jsonObject.contact_phone : null;
    o.name = jsonObject.name ? jsonObject.name : null;
    o.website = jsonObject.website ? jsonObject.website : null;

    return o;
  }
}
