export class Region {
  public id: string;
  public name: string;
  public flag: string;
  public sizes: FingerSize[];
  public default: number;
  constructor() {
    this.default = 0;
    this.sizes = [];
    this.flag = '';
  }

  static create(jsonObject: any) {
    const o = new Region();
    o.id = jsonObject.id ? jsonObject.id : null;
    o.name = jsonObject.name ? jsonObject.name : null;
    o.flag = jsonObject.flag ? jsonObject.flag : null;
    o.default = jsonObject.default ? jsonObject.default : 0;

    if (jsonObject.sizes) {
      for (const size of jsonObject.sizes) {
        const s = FingerSize.createSize(size, o.id );
        o.sizes.push(s);
      }
    }
    return o;
  }


}

export class FingerSize {
  public size: string;
  public diameter: number;
  public regionId: string;
  constructor(regionId: string) {
    this.regionId = regionId;
    this.diameter = 0;
    this.size = '6 1/2';
  }

  static createSize(jsonObject: any, regionId: string) {
    const o = new FingerSize(regionId);
    o.size = jsonObject.size ? jsonObject.size : null;
    o.diameter = jsonObject.diameter ? jsonObject.diameter : 0;
    return o;
  }
}
