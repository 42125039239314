<div class="signContainer">
  <div class="are">
    <div class="left">
      <div class="title">{{'COMMON.CREATE_AN_ACCOUNT' | translate}}</div>


      <div style="padding-top: 40px;padding-bottom: 30px;">Do you want to create an account? Please
        contact us to
        <a style="color: #ff9600;" href="mailto: hello@2shapes.com">hello@2shapes.com</a>
      </div>



      <!-- <form [formGroup]="signupForm" (ngSubmit)="signupFormSubmit()" class="form" [ngClass]="{
                        'has-error':
                          (signupForm.get('email').touched || signupForm.get('email').dirty) &&
                          !signupForm.get('email').valid}">




        <mat-form-field hideRequiredMarker="true">
          <input matInput placeholder="{{'COMMON.FULL_NAME' | translate}}" formControlName="name" />
          <mat-error>{{'COMMON.PLEASE_ENTER_A_NAME' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field hideRequiredMarker="true">
          <input matInput placeholder="{{'COMMON.PHONE' | translate}}" formControlName="phone" />
          <mat-error>{{'COMMON.PLEASE_ENTER_A_PHONE' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field hideRequiredMarker="true">
          <input matInput type="phone" placeholder="{{'COMMON.EMAIL' | translate}}" formControlName="email" />
          <mat-error>{{'COMMON.PLEASE_ENTER_A_VALID_EMAIL' | translate}}</mat-error>
        </mat-form-field>

      <mat-form-field style="width: 100%" hideRequiredMarker="true">
        <mat-label>{{'COMMON.PASSWORD' | translate}}</mat-label>
        <input matInput #password required formControlName="password" (focus)="showPasswordHints=true"
          [type]="showPassword ? 'text' : 'password'" placeholder="{{'COMMON.PASSWORD' | translate}}">
        <mat-icon matSuffix (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}
        </mat-icon>
        <mat-hint [hidden]="!showPasswordHints">{{'ENTRY.SIGNUP.YOUR_PASSWORD_MUST_BE_MINIMUM' | translate
          }}</mat-hint>
      </mat-form-field>

      <div class="mt-3" [hidden]="!showPasswordHints">
        <div class="strength">
          <ul class="strengthBar">
            <li class="point" [style.background-color]="bar0"></li>
            <li class="point" [style.background-color]="bar1"></li>
            <li class="point" [style.background-color]="bar2"></li>
            <li class="point" [style.background-color]="bar3"></li>
          </ul>
          <br>
        </div>
      </div>

      <button mat-raised-button class="whiteTextButton" type="submit" color="primary"
        [disabled]="this.signupForm.valid === false ||passwordStrongness<40">
        {{ 'COMMON.CONTINUE'|translate}}
      </button>


      <a class="agreementLink" href="https://www.2shapes.com/legal"
        target="_blank">{{'ENTRY.SIGNUP.BY_SIGNIN_UP_I_AGREE'
        |
        translate}}</a>
!-->
      <div class="items">
        {{'COMMON.ALREADY_HAVE_AN_ACCOUNT' | translate}}
        <a class="link strong" routerLink="/login">{{'COMMON.LOG_IN' | translate}}</a>
      </div>


    </div>

    <div class="right background-tint">
      <div class="top">
        <img src="assets/img/logow.png" width="150" height="32" />
      </div>
      <div class="texte">
        Design software for custom-made jewelry
      </div>
      <div class="bottom">
      </div>
    </div>
  </div>




</div>