import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeveloperApikey } from '../models/developer-user-apikey';


@Injectable({
    providedIn: 'root'
})
export class DeveloperService {
    private apikey: string;
    private url = 'https://api.2shapes.com/v2/developer/user';
    constructor(private http: HttpClient) {

    }


    public init(apikey: string) {
        this.apikey = apikey;
    }

    addApikey(name: string): Observable<DeveloperApikey> {
        // Headers
        const headers = new HttpHeaders().append('apikey', this.apikey);
        // Parameters
        const info: { [id: string]: any } = {};
        info['name'] = name;

        const formData: FormData = new FormData();
        formData.append('data', JSON.stringify(info));
        return this.http.post(this.url, formData, { headers }).pipe(
            map(res => {

               
                return DeveloperApikey.create(res);
            })
        );
    }

    deleteApikey(id: string): Observable<any> {
        const headers = new HttpHeaders().append('apikey', this.apikey);
        return this.http.delete(this.url + '/' + id, { headers: headers }).pipe(
            map(() => {
                return true;
            })
        );
    }


}


