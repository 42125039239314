<div class="navbarContainer">
    <mat-progress-bar class="floating-progress" *ngIf="loading===true" mode="indeterminate"> </mat-progress-bar>
    <div class="left">

        <button mat-icon-button *ngIf="backIcon" (click)="backEmitter.emit()">
            <span class="material-icons">keyboard_arrow_left</span>
        </button>
        <button *ngIf="labelButton" mat-icon-button
            [style.transform]="isLabelsOpened ? 'rotate(180deg)' : 'rotate(0deg)'"
            style="transition: transform 150ms ease;" (click)="labelButtonClick.emit()">
            <!-- <div class="icon-BOARD_TAG iconx24"></div> -->
            <span class="material-icons">label</span>
        </button>


        <button mat-icon-button *ngIf="settingsLeftButton" (click)="settingsLeftButtonClick.emit()">
            <span class="material-icons">settings</span>
        </button>
        <button mat-icon-button *ngIf="themeLeftButton" (click)="themeLeftButtonClick.emit()">
            <span class="material-icons">
                palette
            </span>
        </button>
        <button mat-icon-button *ngIf="phoneButton" (click)="phoneButtonClick.emit()">
            <span class="material-icons">phone_iphone</span>
        </button>


    </div>
    <div class="middle">
        <img [src]="getLogo()" style="cursor: pointer" (click)="goToHome()" height="45%"
            alt="{{'COMMON.LOGO' | translate}}" />
    </div>
    <div class="right">
        <button mat-icon-button *ngIf="configButton" (click)="configButtonClick.emit()"
            matTooltip="{{'COMMON.CONFIGURATION' | translate}}">
            <span class="material-icons">dvr</span>
        </button>

        <button mat-icon-button *ngIf="filterButton" (click)="filterButtonClick.emit()"
            matTooltip="{{'COMMON.FILTER' | translate}}">
            <span class="material-icons">filter_alt</span>
        </button>

        <button mat-icon-button *ngIf="imagesButton" (click)="imagesButtonClick.emit()"
            matTooltip="{{'COMMON.IMAGES' | translate}}">
            <span class="material-icons">collections</span>
        </button>

        <button mat-icon-button *ngIf="searchButton" (click)="searchButtonClick.emit()">
            <span class="material-icons">search</span>
        </button>


        <button mat-icon-button *ngIf="downloadButton" (click)="downloadButtonClick.emit()">
            <span class="material-icons">cloud_download</span>
        </button>

        <button mat-icon-button *ngIf="addButton" (click)="addButtonClick.emit()" [matTooltip]="addButtonTooltip">
            <span class="material-icons">add_box</span>
        </button>
        <button mat-icon-button *ngIf="settingsButton" (click)="settingsButtonClick.emit()">
            <span class="material-icons">settings</span>
        </button>
        <button mat-icon-button *ngIf="duplicateButton" (click)="duplicateButtonClick.emit()"
            matTooltip="{{'COMMON.DUPLICATE' | translate}}">
            <span class="material-icons">content_copy</span>
        </button>

        <button mat-icon-button *ngIf="uploadButton" (click)="uploadButtonClick.emit()" matTooltip="Upload file">
            <span class="material-icons">upload_file</span>
        </button>
        <button mat-icon-button *ngIf="trendsButton" (click)="trendsButtonClick.emit()"
            [matTooltip]="trendsButtonTooltip">
            <span class="icon-STUDIO_EXTRA" style="font-size: 24px; color: black"></span>
        </button>
        <button mat-icon-button *ngIf="deleteButton" (click)="deleteButtonClick.emit()"
            matTooltip="{{'COMMON.DELETE' | translate}}">
            <span class="material-icons">delete</span>
        </button>
        <button mat-icon-button *ngIf="deleteForeverButton" (click)="deleteForeverButtonClick.emit()"
            matTooltip="Delete Forever">
            <span class="material-icons">delete_forever</span>
        </button>
        <button mat-icon-button *ngIf="sendButton" (click)="sendButtonClick.emit()" matTooltip="Send">
            <span class="material-icons">send</span>
        </button>
        <button mat-icon-button *ngIf="editButton" (click)="editButtonClick.emit()" matTooltip="Edit">
            <span class="material-icons">create</span>
        </button>
        <button mat-icon-button *ngIf="openInStudioButton" (click)="openInStudioButtonClick.emit()">
            <span class="material-icons">3d_rotation</span>
        </button>

        <button mat-icon-button *ngIf="saveButton" (click)="saveButtonClick.emit()">
            <span class="material-icons">save</span>
        </button>

        <button mat-icon-button *ngIf="businessButton" (click)="businessButtonClick.emit()"
            matTooltip="Add Retail Store">
            <span class="material-icons">add_business</span>
        </button>
        <button mat-icon-button *ngIf="addMembreButton" (click)="addMembreButtonClick.emit()"
            matTooltip="{{'COMMON.ADD_MEMBER' | translate}}">
            <span class="material-icons">person_add</span>
        </button>
        <button mat-icon-button *ngIf="searchImageButton" (click)="searchImageButtonClick.emit()"
            matTooltip="{{'COMMON.SEARCH_IMAGE' | translate}}">
            <span class="material-icons">image_search</span>
        </button>
        <!-- <button mat-icon-button *ngIf="showVideoButton == true" (click)="ShowVideo()">
            <span class="material-icons">
                play_circle
            </span>
        </button> -->

        <button mat-icon-button [hidden]="!user">
            <img (click)="goToProfileUser()" class="avatarImage" [src]="getUserAvatar()" />
        </button>

        <button mat-icon-button (click)="sidebarToogle()">
            <span class="material-icons">
                dehaze
            </span>
        </button>
    </div>
</div>