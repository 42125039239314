import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';



@Injectable()
export class AuthguardService implements CanActivateChild {



	constructor(private userService: UserService, private router: Router) {

	}

	canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

		if (this.userService.isLoggedIn) {


			if (this.canUserGo(state.url) == false) {

				if (this.userService.user.hasAccessToDesign()) {
					this.router.navigate(['/design']);
					return of(true);
				}

				if (this.userService.user.hasAccessToBoutique()) {
					this.router.navigate(['/pos']);
					return of(true);
				}

				if (this.userService.user.hasAccessToCRM()) {
					this.router.navigate(['/crm']);
					return of(true);
				}

				if (this.userService.user.hasAccessToInventory()) {
					this.router.navigate(['/inventory']);
					return of(true);
				}
				if (this.userService.user.hasAccessToWorkshop()) {
					this.router.navigate(['/workshop']);
					return of(true);
				}

				if (this.userService.user.hasAccessToDashboard()) {
					this.router.navigate(['/dashboard']);
					return of(true);
				}



				return of(true);
			}

			return of(true);

		}
		return this.userService.existUserAndIsValidInBackend()
			.pipe(map((res: boolean) => {
				if (!res) {
					this.router.navigate(['/login'], { queryParams: { url: state.url } });
				} else {

					if (this.userService.user.dark_mode) {
						document.documentElement.setAttribute('theme', 'dark');
					}
					// if (this.userService.user.hasAccessToDesign()) {
					// 	this.router.navigate(['/design']);
					// 	return true;
					// }

					// if (this.userService.user.hasAccessToPOS()) {
					// 	this.router.navigate(['/pos']);
					// 	return true;
					// }

					// if (this.userService.user.hasAccessToCRM()) {
					// 	this.router.navigate(['/crm']);
					// 	return true;
					// }

					// if (this.userService.user.hasAccessToInventory()) {
					// 	this.router.navigate(['/inventory']);
					// 	return true;
					// }
					// if (this.userService.user.hasAccessToWorkshop()) {
					// 	this.router.navigate(['/workshop']);
					// 	return true;
					// }

					// if (this.userService.user.hasAccessToDashboard()) {
					// 	this.router.navigate(['/dashboard']);
					// 	return true;
					// }



					return true;
				}
				return res;
			}));

	}


	canUserGo(url: string): boolean {

		return true;
		if (!url) { return false; }

		if (url.startsWith('/home')) {
			return true;
		}
		if (url.startsWith('/store')) {
			if (this.userService.user.isCloudLicenseValid() == false) { return false; }
			if (this.isStore() == true) { return true; }
			return false;
		}
		if (url.startsWith('/sales')) {
			if (this.userService.user.isCloudLicenseValid() == false) { return false; }
			if (this.isStore() == true) { return true; }
			return false;
		}
		if (url.startsWith('/catalogue')) {
			if (this.userService.user.isCloudLicenseValid() == false) { return false; }
			if (this.isCatalogue() == true) { return true; }
			return false;
		}
		if (url.startsWith('/pricing')) {
			if (this.userService.user.isCloudLicenseValid() == false && this.userService.user.isDesignLicenseValid() == false) { return false; }
			if (this.isPricing() == true) { return true; }
			return false;
		}
		if (url.startsWith('/design')) {
			if (this.userService.user.isCloudLicenseValid() == false && this.userService.user.isDesignLicenseValid() == false) { return false; }
			if (this.isDesign() == true) { return true; }
			return false;
		}
		if (url.startsWith('/tasks')) {
			if (this.userService.user.isCloudLicenseValid() == false) { return false; }
			if (this.isTask() == true) { return true; }
			return false;
		}


		if (url.startsWith('/ecommerce')) {
			if (this.userService.user.isCloudLicenseValid() == false) { return false; }
			if (this.isCommerce() == true) { return true; }
			return false;
		}
		if (url.startsWith('/business')) {
			if (this.userService.user.isCloudLicenseValid() == false) { return false; }
			if (this.isBusiness() == true) { return true; }
			return false;
		}
	}

	isStore() {
		if (this.userService.user?.member?.permissions?.includes('ROLE_OWNER')) {
			return true;
		}
		return this.userService.user?.member?.permissions?.includes('ROLE_STORE');
	}
	isTask() {
		if (this.userService.user?.member?.permissions?.includes('ROLE_OWNER')) {
			return true;
		}
		return this.userService.user?.member?.permissions?.includes('ROLE_TASK');
	}
	isDesign() {

		if (this.userService.user?.member?.permissions?.includes('ROLE_OWNER')) {
			return true;
		}
		return this.userService.user?.member?.permissions?.includes('ROLE_DESIGN');
	}
	isPricing() {

		if (this.userService.user?.member?.permissions?.includes('ROLE_OWNER')) {
			return true;
		}
		return this.userService.user?.member?.permissions?.includes('ROLE_PRICING');
	}
	isBusiness() {

		if (this.userService.user?.member?.permissions?.includes('ROLE_OWNER')) {
			return true;
		}
		return this.userService.user?.member?.permissions?.includes('ROLE_BUSINESS');
	}

	isCatalogue() {

		if (this.userService.user?.member?.permissions?.includes('ROLE_OWNER')) {
			return true;
		}
		return this.userService.user?.member?.permissions?.includes('ROLE_CATALOGUE');
	}

	isBranding() {

		if (this.userService.user?.member?.permissions?.includes('ROLE_OWNER')) {
			return true;
		}
		return this.userService.user?.member?.permissions?.includes('ROLE_BRANDING');
	}
	isCommerce() {

		if (this.userService.user?.member?.permissions?.includes('ROLE_OWNER')) {
			return true;
		}
		return this.userService.user?.member?.permissions?.includes('ROLE_ECOMMERCE');
	}




}
