import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upgrade-dialog',
  templateUrl: './upgrade-dialog.component.html',
  styleUrls: ['./upgrade-dialog.component.sass'],
  providers: [TranslatePipe]
})
export class UpgradeDialogComponent implements OnInit {

  customMessage: string;
  constructor(
    public thisDialogRef: MatDialogRef<UpgradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {



  }

  ngOnInit() {
    if (this.data['message']) {
      this.customMessage = this.translateService.instant('COMMON.UPGRADE_TO_PLAN_TO_UNLOCK', { 'plan': this.data['message'] });
    }
  }

  onCloseConfirm() {
    this.thisDialogRef.close(true);
  }

  onCloseCancel() {
    this.thisDialogRef.close(false);
  }



}
