export const environment = {
  production: true,
  betaFeatures: false,
  sentry: true,
  baseUrl: 'https://app.2shapes.com',
  apiUrl: 'https://api.2shapes.com',
  apolloUrl: 'https://hub.2shapes.com:2053',
  stripe_token: 'pk_live_zlL5qoVlxKBD9IHm30XNlAF0',
  defaultAvatar: 'https://storage.googleapis.com/2shapes/defaults/avatar.png',
  templateDiamondsCSV: 'https://storage.googleapis.com/2shapes-public/templates/2Shapes%20-%20Diamonds%20Certified%20Example%20(SEP2019).csv',
  templateDiamondsNCCSV: 'https://storage.googleapis.com/2shapes-public/templates/2Shapes%20-%20Diamonds%20Non%20Certified%20Example%20(JUL2019).csv',
  productKey: '2SHAPES-TRIAL-30-SUMMER19',
  gtm_id: 'GTM-NP5W67X',
  apollo_in_local: false,
  isMobile: false
};
