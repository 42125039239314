<div class="signContainer">
  <div class="are">
    <div class="left">


      <div *ngIf="serverError?.length>0 && !showValidateOK">

        <div class="title">
          {{serverError | translate}}
        </div>
      </div>

      <div *ngIf="showValidateOK">

        <div class="title">{{'ENTRY.LOGIN.ACTIVATED' | translate}}</div>

        <button mat-raised-button class="whiteTextButton" [routerLink]="['/login']" color="primary">
          {{'COMMON.CONTINUE'| translate }}
        </button>
      </div>

      <div class="items">{{'COMMON.NEED_HELP' | translate}} <a target="_blank" href="https://www.2shapes.com/contactus">
          {{'COMMON.CONTACT_US' | translate}}</a></div>
    </div>

    <div class="right background-tint">
      <div class="top">
        <img src="assets/img/logow.png" width="150" height="32" />
      </div>
      <div class="texte">
        Design software for custom-made jewelry
      </div>
      <div class="bottom">
      </div>
    </div>
  </div>
</div>