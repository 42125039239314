import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FontsService {

  constructor(private http: HttpClient) {




  }

  getFonts(): Observable<any> {

    return this.http.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBAMbTLnrtSRewCkFV7dTY05uwZ9-xPj3E').pipe(
      map(res => {
        const array = <[]>res['items'];
        let names = [];
        array.map(f => {
          names.push(f['family']);
        });
        // Sembla que la font Buda dona error a Google, per això, l'ignorem
        names = names.filter(f => f != 'Buda');
        names = names.filter(f => f != 'Coda Caption');
        names = names.filter(f => f != 'Molle');
        names = names.filter(f => f != 'Open Sans Condensed');
        names = names.filter(f => f != 'UnifrakturCook');
        names = names.filter(f => f != 'Sunflower');
        return names;
      }));
  }
}
