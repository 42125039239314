import { Pipe, PipeTransform } from '@angular/core';
import { ListsService } from '../services/lists.service';

@Pipe({
  name: 'iisCurrency'
})
export class IisCurrencyPipe implements PipeTransform {


  constructor(private listService: ListsService) {
  }
  transform(value: number, arg1: any, arg2: any): string {


    return this.listService.getPrice(value, arg1, arg2);
  }



}

