export class OrganizationSubscription {
    public id: string;
    public created_at: string;
    public expired_at: Date;
    public updated_at: string;
    public clau: string;
    public license_type: string;
    public product: string;

    static create(jsonObject: any): OrganizationSubscription {
        const o = new OrganizationSubscription();
        o.id = jsonObject.id ? jsonObject.id : null;
        o.clau = jsonObject.clau ? jsonObject.clau : null;
        o.license_type = jsonObject.license_type ? jsonObject.license_type : null;
        o.product = jsonObject.product ? jsonObject.product : null;

        o.created_at = jsonObject.created_at ? jsonObject.created_at : null;
        o.expired_at = jsonObject.expired_at ? jsonObject.expired_at : null;
        o.updated_at = jsonObject.updated_at ? jsonObject.updated_at : null;


        return o;

    }

    constructor() {

    }

}
