import { HttpClient } from '@angular/common/http';
import { Component, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { Analytics } from './shared/models/analytics';
import { AnalyticsService } from './shared/services/business/analytics.service';
import { MixpanelService } from './shared/services/mixpanel.service';

declare var gtag;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {

  analytics: Analytics;
  loading = true;
  googleAnalytics: string;

  constructor(translate: TranslateService, router: Router,
    public analyticsService: AnalyticsService,
    public vcRef: ViewContainerRef,
    private mixpanel: MixpanelService,
    public http: HttpClient) {


    // Inicialitzem les dades per business

    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    // Google Tag Manager
    navEndEvents.subscribe((event: NavigationEnd) => {
      // gtag('config', 'G-4PY4RPQXB3', {
      //   'page_path': event.urlAfterRedirects
      // });

      const url = event.urlAfterRedirects;
      this.mixpanelTrackEvent(url);

    });


    //Google Tag Manager
    navEndEvents.subscribe((event: NavigationEnd) => {
      if (event.urlAfterRedirects == '/' || event.urlAfterRedirects.includes('validate')) {
        gtag('config', 'G-0K29ZZSDRB', {
          'page_path': event.urlAfterRedirects
        });

        gtag('event', 'account_created');


        // const gtmTag = {
        //   event: 'page_path',
        //   pageName: event.urlAfterRedirects
        // };

        // this.gtmService.pushTag(gtmTag);

      }
    });

    translate.addLangs(['en', 'es', 'fr', 'ca', 'it', 'ja', 'pt', 'pl', 'ko', 'de', 'zhchs', 'zhcht', 'hi', 'id', 'bn', 'ur', 'hy', 'tr', 'nl', 'cs', 'bg', 'da', 'fa', 'hr', 'km', 'uk']);
    translate.setDefaultLang('en');

  }


  mixpanelTrackEvent(url: string) {


    let event = null;
    if (url.includes("/design/studio/design/")) event = "APP_DESIGN_OPEN_STUDIO"

    if (url == '/inventory/spaces') event = "APP_INVENTORY_SPACES";
    if (url == '/inventory/collections') event = "APP_INVENTORY_COLLECTIONS";
    if (url == '/inventory/products') event = "APP_INVENTORY_PRODUCTS";
    if (url == '/inventory/media') event = "APP_INVENTORY_MEDIA";
    if (url == '/inventory/manufacturers') event = "APP_INVENTORY_MANUFACTURERS";
    if (url == '/inventory/diamonds') event = "APP_INVENTORY_DIAMONDS";
    if (url == '/inventory/markups') event = "APP_INVENTORY_MARKUPS";
    if (url == '/inventory/taxes') event = "APP_INVENTORY_TAXES";
    if (url == '/inventory/shipments') event = "APP_INVENTORY_SHIPMENTS";
    if (url == '/inventory/simulator') event = "APP_INVENTORY_SIMULATOR";


    if (url == '/crm/customers') event = "APP_CRM_CUSTOMER";
    if (url == '/crm/quotes') event = "APP_CRM_QUOTES";
    if (url == '/crm/sales') event = "APP_CRM_SALES";
    if (url == '/crm/celebrations') event = "APP_CRM_CELEBRATIONS";
    if (url == '/crm/hints') event = "APP_CRM_HINTS";
    if (url == '/crm/subscribers') event = "APP_CRM_SUBSCRIBERS";

    if (url == '/help') event = "APP_HELP";

    if (url.includes("/workshop/project")) event = "APP_WORKSHOP_PROJECT";

    if (url.includes("/dashboard/billing/billing?plan=yes")) event = "APP_DASHBOARD_BILLING";


    if (url.includes("/dashboard/main/organization")) event = "APP_DASHBOARD_MAIN_ORGANIZATION";
    if (url.includes("/dashboard/main/accounts")) event = "APP_DASHBOARD_MAIN_ACCOUNTS";
    if (url.includes("/dashboard/main/invoicing")) event = "APP_DASHBOARD_MAIN_INVOICING";
    if (url.includes("/dashboard/main/integrations")) event = "APP_DASHBOARD_MAIN_INTEGRATIONS";

    if (url == '/dashboard/store') event = "APP_DASHBOARD_STORE_ORGANIZATION";
    if (url.includes("/dashboard/store/stores")) event = "APP_DASHBOARD_STORE_STORES";
    if (url.includes("/dashboard/store/catalogue")) event = "APP_DASHBOARD_STORE_CATALOGUE";
    if (url.includes("/dashboard/store/pricing")) event = "APP_DASHBOARD_STORE_PRICING";
    if (url.includes("/dashboard/store/legal")) event = "APP_DASHBOARD_STORE_LEGAL";
    if (url.includes("/dashboard/store/status")) event = "APP_DASHBOARD_STORE_STATUS";


    if (url.includes("/dashboard/online")) event = "APP_DASHBOARD_ONLINE";
    if (url.includes("/dashboard/online/new")) event = "APP_DASHBOARD_ONLINE_NEW";

    if (url.includes("/dashboard/marketplace")) event = "APP_DASHBOARD_MARKETPLACE";

    if (url.includes("/dashboard/billing/billing")) event = "APP_DASHBOARD_BILLING_BILLING";
    if (url.includes("/dashboard/billing/invoices")) event = "APP_DASHBOARD_BILLING_INVOICES";

    if (event) this.mixpanel.trackEvent(event);
  }
}
