export class PasswordTools {

  public static  passwordScore(password: string): number {
	let score = 0;

	if (password.length === 0) {
		return 0;
	}
	if (password.length > 8) {
		score++;
	}
	if (password.length > 12) {
		score++;
	}
	const upperCount = this.countUpperCaseChars(password);
	const lowerCount = this.countLowerCaseChars(password);

	if (upperCount > 0) {
		score++;
	}
	if (lowerCount > 0) {
		score++;
	}
	const isAlphanumeric = this.isAlphanumeric(password);

	if (!isAlphanumeric) {
		score++;
	}

	const hasNumbers = this.hasNumbers(password);
	if (hasNumbers) {
		score++;
	}

	if (score === 0) {
		return 0;
	}
	if (score === 1) {
		return 15;
	}
	if (score === 2) {
		return 30;
	}
	if (score === 3) {
		return 40;
	}
	if (score === 4) {
		return 60;
	}
	if (score === 5) {
		return 80;
	}
	if (score === 6) {
		return 100;
	}

  }

  // Check if a string is alphanumeric
  // it helps to see there is special chars
  private static   isAlphanumeric(str: string) {
	return /^[0-9a-zA-Z]+$/.test(str);
  }

  private static hasNumbers(t) {
	const regex = /\d/g;
	return regex.test(t);
  }


  private static  countUpperCaseChars(str: string) {
	let count = 0;
	const len = str.length;
	for (let i = 0; i < len; i++) {
		if (/[A-Z]/.test(str.charAt(i))) {
		count++;
		}
	}
	return count;
  }

  private static  countLowerCaseChars(str: string) {
	let count = 0;
	const len = str.length;
	for (let i = 0; i < len; i++) {
		if (/[a-z]/.test(str.charAt(i))) {
		count++;
		}
	}
	return count;
  }


  public static SHA1(msg) {


	function rotate_left(n, s) {
		const t4 = ( n << s ) | (n >>> (32 - s));
		return t4;
	}


	function lsb_hex(val) {
		let str = '';
		let y;
		let vh;
		let vl;

		for (y = 0; y <= 6; y += 2) {
		vh = (val >>> (y * 4 + 4)) & 0x0f;
		vl = (val >>> (y * 4)) & 0x0f;
		str += vh.toString(16) + vl.toString(16);
		}
		return str;
	}


	function cvt_hex(val) {
		let str = '';
		let y;
		let v;
		for (y = 7; y >= 0; y--) {
		v = (val >>> (y * 4)) & 0x0f;
		str += v.toString(16);
		}
		return str;
	}

	function Utf8Encode(string) {
		string = string.replace(/\r\n/g, '');
		let utftext = '';
		for (let n = 0; n < string.length; n++) {
		const c = string.charCodeAt(n);
		if (c < 128) {
			utftext += String.fromCharCode(c);
		} else if ((c > 127) && (c < 2048)) {
			utftext += String.fromCharCode((c >> 6) | 192);
			utftext += String.fromCharCode((c & 63) | 128);
		} else {
			utftext += String.fromCharCode((c >> 12) | 224);
			utftext += String.fromCharCode(((c >> 6) & 63) | 128);
			utftext += String.fromCharCode((c & 63) | 128);
		}
		}
		return utftext;
	}

	let blockstart;
	let i, j;
	const W = new Array(80);
	let H0 = 0x67452301;
	let H1 = 0xEFCDAB89;
	let H2 = 0x98BADCFE;
	let H3 = 0x10325476;
	let H4 = 0xC3D2E1F0;
	let A, B, C, D, E;
	let temp: any;
	msg = Utf8Encode(msg);
	const msg_len = msg.length;
	const word_array = [];

	for (i = 0; i < msg_len - 3; i += 4) {
		j = msg.charCodeAt(i) << 24 | msg.charCodeAt(i + 1) << 16 |
		msg.charCodeAt(i + 2) << 8 | msg.charCodeAt(i + 3);
		word_array.push(j);
	}
	switch (msg_len % 4) {
		case 0:
		i = 0x080000000;
		break;
		case 1:
		i = msg.charCodeAt(msg_len - 1) << 24 | 0x0800000;
		break;
		case 2:
		i = msg.charCodeAt(msg_len - 2) << 24 | msg.charCodeAt(msg_len - 1) << 16 | 0x08000;
		break;
		case 3:
		i = msg.charCodeAt(msg_len - 3) << 24 | msg.charCodeAt(msg_len - 2) << 16 | msg.charCodeAt(msg_len - 1) << 8 | 0x80;
		break;
	}
	word_array.push(i);
	while ((word_array.length % 16) !== 14) {
		word_array.push(0);
	}

	word_array.push(msg_len >>> 29);
	word_array.push((msg_len << 3) & 0x0ffffffff);

	for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
		for (i = 0; i < 16; i++) {
		W[i] = word_array[blockstart + i];
		}
		for (i = 16; i <= 79; i++) {
		W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
		}
		A = H0;
		B = H1;
		C = H2;
		D = H3;
		E = H4;

		for (i = 0; i <= 19; i++) {
		temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
		E = D;
		D = C;
		C = rotate_left(B, 30);
		B = A;
		A = temp;
		}

		for (i = 20; i <= 39; i++) {
		temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
		E = D;
		D = C;
		C = rotate_left(B, 30);
		B = A;
		A = temp;
		}

		for (i = 40; i <= 59; i++) {
		temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
		E = D;
		D = C;
		C = rotate_left(B, 30);
		B = A;
		A = temp;
		}

		for (i = 60; i <= 79; i++) {
		temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
		E = D;
		D = C;
		C = rotate_left(B, 30);
		B = A;
		A = temp;
		}

		H0 = (H0 + A) & 0x0ffffffff;
		H1 = (H1 + B) & 0x0ffffffff;
		H2 = (H2 + C) & 0x0ffffffff;
		H3 = (H3 + D) & 0x0ffffffff;
		H4 = (H4 + E) & 0x0ffffffff;
	}
	temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
	return temp.toLowerCase();
  }

}
