import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { User } from '../../models/user.interface';
import { HelpService } from '../../services/help.service';
import { LoadingService } from '../../services/loading.service';
import { LearnYoutubeDialogComponent } from '../learn-youtube-dialog/learn-youtube-dialog.component';

@Component({
  selector: 'basic-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass']
})
export class NavbarComponent implements OnInit {

  public logo = './assets/img/LOGO_GREY_BASIC.png';

  @Input() border_bottom = true;
  @Input() backIcon = true;

  @Output() backEmitter = new EventEmitter();

  @Input() showDesignerButtons = false;
  @Input() title = '';

  @Input() showDiscover = false;
  @Input() showYourProjects = false;
  @Input() appsIcon = false;

  @Input() labelButton = false;
  @Output() labelButtonClick = new EventEmitter();

  @Input() settingsLeftButton = false;
  @Output() settingsLeftButtonClick = new EventEmitter();

  @Input() themeLeftButton = false;
  @Output() themeLeftButtonClick = new EventEmitter();

  @Input() imagesButton = false;
  @Output() imagesButtonClick = new EventEmitter();

  @Input() phoneButton = false;
  @Output() phoneButtonClick = new EventEmitter();


  @Input() searchButton = false;
  @Output() searchButtonClick = new EventEmitter();

  @Input() searchImageButton = false;
  @Output() searchImageButtonClick = new EventEmitter();
  @Input() trendsButton = false;
  @Output() trendsButtonClick = new EventEmitter();
  @Input() trendsButtonTooltip = 'Trends';

  @Input() addButton = false;
  @Output() addButtonClick = new EventEmitter();
  @Input() addButtonTooltip = 'Add';


  @Input() duplicateButton = false;
  @Output() duplicateButtonClick = new EventEmitter();
  @Input() deleteButton = false;
  @Output() deleteButtonClick = new EventEmitter();
  @Input() uploadButton = false;
  @Output() uploadButtonClick = new EventEmitter();


  @Input() settingsButton = false;
  @Output() settingsButtonClick = new EventEmitter();
  @Input() configButton = false;
  @Output() configButtonClick = new EventEmitter();
  @Input() filterButton = false;
  @Output() filterButtonClick = new EventEmitter();

  @Input() sendButton = false;
  @Output() sendButtonClick = new EventEmitter();
  @Input() editButton = false;
  @Output() editButtonClick = new EventEmitter();
  @Input() openInStudioButton = false;
  @Output() openInStudioButtonClick = new EventEmitter();

  @Input() saveButton = false;
  @Output() saveButtonClick = new EventEmitter();

  @Input() downloadButton = false;
  @Output() downloadButtonClick = new EventEmitter();

  @Input() businessButton = false;
  @Output() businessButtonClick = new EventEmitter();
  @Input() addMembreButton = false;
  @Output() addMembreButtonClick = new EventEmitter();

  @Input() deleteForeverButton = false;
  @Output() deleteForeverButtonClick = new EventEmitter();

  @Output() ordersButtonClick = new EventEmitter();
  @Output() adminButtonClick = new EventEmitter();

  @Output() settingsEmitter = new EventEmitter();
  @Output() feedbackEmmitter = new EventEmitter();
  @Output() sidebarEmmitter = new EventEmitter();

  @Input() helpIcon = false;
  @Input() notificacions: string[] = [];
  @Input() activeCost = false;


  @Input() user: User;
  @Output() userProfileEmitter = new EventEmitter();
  borderColor = '';
  backcolor = '';
  role = '';
  selectedIndex = 0;
  loading: boolean;

  showVideoButton = false;
  idVideo = '';

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private loadingService: LoadingService,
    public helpService: HelpService) {

    this.helpService.VideoAvailable.subscribe(res => {
      this.showVideoButton = res.available;
      this.idVideo = res.url;
    });

  }

  ngOnInit() {
    this.loadingService.onLoadingChange.pipe(delay(0)).subscribe(res => {
      this.loading = res;
    });

  }

  getLogo() {
    return 'assets/img/LOGO_GREY_BASIC.png';
  }

  getUserAvatar() {
    if (this.user?.avatar) {
      return this.user?.avatar;
    }
    return 'assets/avatar.png';
  }

  sidebarToogle() {
    this.sidebarEmmitter.emit();
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  goToProfileUser() {
    this.router.navigate(['/home/profile']);
  }

  goToHelp() {
    window.open('https://www.2shapes.com/learn', '_blank');
  }

  ShowVideo() {
    this.dialog.open(LearnYoutubeDialogComponent, {
      width: '850px',
      data: {
        videoid: this.idVideo
      }
    });
  }
}
