import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { MessageService } from "../../shared/services/message.service";


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private messageService: MessageService,


        private zone: NgZone) { }

    handleError(error: Error) {

        this.zone.run(() =>
            this.messageService.showErrorMessage(
                error.message || "Undefined client error"
            ));

        console.error("Error from global error handler", error);
    }
}
