export class DeveloperApikey {
    public id: string;
    public name: string;
    public apikey: string;
    public created_at: Date;

    constructor() {

    }

    static create(jsonObject: any) {
        const o = new DeveloperApikey();
        o.id = jsonObject.id ? jsonObject.id : null;
        o.name = jsonObject.name ? jsonObject.name : null;
        o.apikey = jsonObject.apikey ? jsonObject.apikey : null;
        o.created_at = jsonObject.created_at ? jsonObject.created_at : null;

        return o;
    }


}