import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AnalyticsInfo } from '../../models/AnalyticsInfo';
import { UserService } from '../user.service';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {


  private urlEcommerce = environment.apiUrl + '/v1/analytics/ecommerce';
  private urlEcommerceStore = environment.apiUrl + '/v1/analytics/store';
  private urlEcommerceGoogle = environment.apiUrl + '/v1/analytics/google';

  analytics7: AnalyticsInfo = null;
  analytics30: AnalyticsInfo = null;

  constructor(private http: HttpClient, private userService: UserService) { }


  getAnalytics(days: number): Observable<AnalyticsInfo[]> {

    const url = this.urlEcommerce + '/' + days;
    const headers = new HttpHeaders().append('apikey', this.userService.user.apikey);

    return this.http.get(url, { headers: headers }).pipe(
      map(res => {
        const analytics = [];
        const arr = <any>res;
        arr.map((info: any) => {
          const analytic = AnalyticsInfo.create(info);
          analytics.push(analytic);
        });
        return analytics;
      })
    );
  }

  getAnalyticsStore(days: number): Observable<AnalyticsInfo> {

    const url = this.urlEcommerceStore + '/' + days;
    const headers = new HttpHeaders().append('apikey', this.userService.user.apikey);
    return this.http.get(url, { headers: headers }).pipe(
      map(res => {
        return AnalyticsInfo.create(res);
      })
    );
  }

  postGoogleAnalyticsTrackingId(trackingID?: string): Observable<AnalyticsInfo> {
    const url = this.urlEcommerceGoogle;
    // Headers


    const headers = new HttpHeaders().append('apikey', this.userService.user.apikey);

    // Parameters
    const info: { [id: string]: any } = {};

    if (trackingID) {
      info['google_analytics_tracking_id'] = trackingID;
    }

    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(info));

    return this.http.post(url, formData, { headers: headers }).pipe(
      map(res => {
        return AnalyticsInfo.create(res);
      })
    );
  }
}

