
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    public cookieLawAccepted = true;


    // isCookieLawAccepted(): boolean {
    //     this.cookieLawAccepted = LocalStorageTools.existInLocalStorage();
    //     return this.cookieLawAccepted;
    // }

    // setCookieLawAccepted(): boolean {
    //     this.cookieLawAccepted = LocalStorageTools.saveLocalStorage(this.ecommerceName);
    //     return this.cookieLawAccepted;
    // }
}
