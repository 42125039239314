import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Member } from '../../models/member';
import { UserService } from '../user.service';


@Injectable({
  providedIn: 'root'
})
export class ProfileTeamService {


  private urlTeam = environment.apiUrl + '/v1/organization/team';

  constructor(private http: HttpClient, private userService: UserService) { }

  getTeam(): Observable<Member[]> {

    const headers = new HttpHeaders().append('apikey', this.userService.user.apikey);

    return this.http.get(this.urlTeam, {
      headers: headers
    }).pipe(
      map(res => {
        const members = [];
        const memberJson = <[]>res;
        memberJson.map(e => {
          members.push(Member.create(e));
        });
        return members;
      })
    );
  }
}
