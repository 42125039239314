
import { Member } from './member';
import { OrganizationSubscription } from './organization_subscription';
import { User } from './user.interface';

export class Organization {
  public id: string; //
  public name: string; //
  public address: string; // phone, email, contact method
  public phone: string;
  public email: string;
  public contact_method: string;
  public city: string;
  public logo: string;
  public section: string;
  public zip: string;
  public state_or_province: string;
  public country: string;
  public vat_number: string;
  public plan_id: string;
  public plan_members: number;

  public language: string;
  public currency: string;
  public finger_region: string;
  public default_metal: string;
  public no_price_text: string;
  public admin: User;
  public users: User[];
  public members: Member[];
  public isValid: boolean;

  public stripeCustomerId: string;

  public web: string;
  public twitter: string;
  public instagram: string;
  public facebook?: string;
  public google_analytics_tracking_id: string;

  public notifications_global: string[];
  public notifications_retail: string[];
  public notifications_ecomm: string[];
  public notifications_crm: string[];
  public notifications_catalogue: string[];
  public notifications_pricing: string[];
  public notifications_factory: string[];
  public notifications_design: string[];
  public notifications_for_rhino: string[];
  public notifications_diamonds: string[];

  public emailsender_name: string;
  public emailsender_email: string;
  public emailsender_login: string;
  public emailsender_password: string;
  public emailsender_server: string;
  public emailsender_port: string;

  public invoicing_platform: string;
  public invoicing_quickbooks_apikey: string;
  public invoicing_invoice_message_to_customer: string;
  public invoicing_invoice_add_to_footer: string;
  public invoicing_invoice_color: string;
  public invoicing_invoice_prefix: string;
  public invoicing_invoice_suffix: string;
  public invoicing_email_subject: string;
  public invoicing_email_greeting_type: string;
  public invoicing_email_message: string;
  public invoicing_receipt_prefix: string;
  public invoicing_receipt_suffix: string;

  public organization_subscriptions: OrganizationSubscription[];

  static create(jsonObject: any): Organization {

    const o = new Organization();
    o.id = jsonObject.id ? jsonObject.id : null;
    o.name = jsonObject.name ? jsonObject.name : null;
    o.address = jsonObject.address ? jsonObject.address : null;
    o.phone = jsonObject.phone ? jsonObject.phone : null;
    o.email = jsonObject.email ? jsonObject.email : null;
    o.no_price_text = jsonObject.no_price_text ? jsonObject.no_price_text : null;
    o.contact_method = jsonObject.contact_method ? jsonObject.contact_method : false;
    o.city = jsonObject.city ? jsonObject.city : null;
    o.zip = jsonObject.zip ? jsonObject.zip : null;
    o.state_or_province = jsonObject.state_or_province ? jsonObject.state_or_province : null;
    o.country = jsonObject.country ? jsonObject.country : null;
    o.vat_number = jsonObject.vat_number ? jsonObject.vat_number : null;
    o.language = jsonObject.language ? jsonObject.language : null;
    o.isValid = jsonObject.is_valid ? jsonObject.is_valid : false;
    o.stripeCustomerId = jsonObject.stripe_customer_id ? jsonObject.stripe_customer_id : null;
    o.plan_id = jsonObject.plan_id ? jsonObject.plan_id : null;
    o.plan_members = jsonObject.plan_members ? jsonObject.plan_members : 1;

    o.admin = jsonObject.admin ? <User>jsonObject.admin : null;
    o.users = jsonObject.users ? <User[]>jsonObject.users : null;
    o.logo = jsonObject.logo ? jsonObject.logo : null;
    o.section = jsonObject.section ? jsonObject.section : null;
    o.web = jsonObject.web ? jsonObject.web : null;
    o.twitter = jsonObject.twitter ? jsonObject.twitter : null;
    o.instagram = jsonObject.instagram ? jsonObject.instagram : null;
    o.facebook = jsonObject.facebook ? jsonObject.facebook : null;
    o.currency = jsonObject.currency ? jsonObject.currency : null;
    o.finger_region = jsonObject.finger_region ? jsonObject.finger_region : null;
    o.default_metal = jsonObject.default_metal ? jsonObject.default_metal : 'GOLD_WHITE_18';
    o.no_price_text = jsonObject.no_price_text ? jsonObject.no_price_text : '';
    o.google_analytics_tracking_id = jsonObject.google_analytics_tracking_id ? jsonObject.google_analytics_tracking_id : null;

    o.emailsender_name = jsonObject.emailsender_name ? jsonObject.emailsender_name : null;
    o.emailsender_email = jsonObject.emailsender_email ? jsonObject.emailsender_email : null;
    o.emailsender_login = jsonObject.emailsender_login ? jsonObject.emailsender_login : null;
    o.emailsender_password = jsonObject.emailsender_password ? jsonObject.emailsender_password : null;
    o.emailsender_server = jsonObject.emailsender_server ? jsonObject.emailsender_server : null;
    o.emailsender_port = jsonObject.emailsender_port ? jsonObject.emailsender_port : null;

    o.invoicing_platform = jsonObject.invoicing_platform ? jsonObject.invoicing_platform : null;
    o.invoicing_quickbooks_apikey = jsonObject.invoicing_quickbooks_apikey ? jsonObject.invoicing_quickbooks_apikey : null;
    o.invoicing_invoice_message_to_customer = jsonObject.invoicing_invoice_message_to_customer ? jsonObject.invoicing_invoice_message_to_customer : null;
    o.invoicing_invoice_add_to_footer = jsonObject.invoicing_invoice_add_to_footer ? jsonObject.invoicing_invoice_add_to_footer : null;
    o.invoicing_invoice_color = jsonObject.invoicing_invoice_color ? jsonObject.invoicing_invoice_color : null;
    o.invoicing_invoice_prefix = jsonObject.invoicing_invoice_prefix ? jsonObject.invoicing_invoice_prefix : null;
    o.invoicing_invoice_suffix = jsonObject.invoicing_invoice_suffix ? jsonObject.invoicing_invoice_suffix : null;
    o.invoicing_email_subject = jsonObject.invoicing_email_subject ? jsonObject.invoicing_email_subject : null;
    o.invoicing_email_greeting_type = jsonObject.invoicing_email_greeting_type ? jsonObject.invoicing_email_greeting_type : null;
    o.invoicing_email_message = jsonObject.invoicing_email_message ? jsonObject.invoicing_email_message : null;

    o.invoicing_receipt_prefix = jsonObject.invoicing_receipt_prefix ? jsonObject.invoicing_receipt_prefix : null;
    o.invoicing_receipt_suffix = jsonObject.invoicing_receipt_suffix ? jsonObject.invoicing_receipt_suffix : null;

    if (jsonObject.members) {
      for (const mem of jsonObject.members) {
        const m = Member.create(mem);
        o.members.push(m);
      }
    }

    if (jsonObject.notifications_global) {
      for (const notification of jsonObject.notifications_global) {
        o.notifications_global.push(notification);
      }
    }

    if (jsonObject.notifications_retail) {
      for (const notification of jsonObject.notifications_retail) {
        o.notifications_retail.push(notification);
      }
    }

    if (jsonObject.notifications_ecomm) {
      for (const notification of jsonObject.notifications_ecomm) {
        o.notifications_ecomm.push(notification);
      }
    }

    if (jsonObject.notifications_crm) {
      for (const notification of jsonObject.notifications_crm) {
        o.notifications_crm.push(notification);
      }
    }

    if (jsonObject.notifications_catalogue) {
      for (const notification of jsonObject.notifications_catalogue) {
        o.notifications_catalogue.push(notification);
      }
    }

    if (jsonObject.notifications_pricing) {
      for (const notification of jsonObject.notifications_pricing) {
        o.notifications_pricing.push(notification);
      }
    }

    if (jsonObject.notifications_factory) {
      for (const notification of jsonObject.notifications_factory) {
        o.notifications_factory.push(notification);
      }
    }

    if (jsonObject.notifications_design) {
      for (const notification of jsonObject.notifications_design) {
        o.notifications_design.push(notification);
      }
    }

    if (jsonObject.notifications_for_rhino) {
      for (const notification of jsonObject.notifications_for_rhino) {
        o.notifications_for_rhino.push(notification);
      }
    }

    if (jsonObject.notifications_diamonds) {
      for (const notification of jsonObject.notifications_diamonds) {
        o.notifications_diamonds.push(notification);
      }
    }

    if (jsonObject.organization_subscriptions) {
      for (const subscription of jsonObject.organization_subscriptions) {
        const s = OrganizationSubscription.create(subscription);
        o.organization_subscriptions.push(s);
      }
    }


    return o;
  }

  constructor() {
    this.members = new Array();
    this.name = '';
    this.default_metal = 'GOLD_WHITE_18';

    this.notifications_global = [];
    this.notifications_retail = [];
    this.notifications_ecomm = [];
    this.notifications_crm = [];
    this.notifications_catalogue = [];
    this.notifications_pricing = [];
    this.notifications_factory = [];
    this.notifications_design = [];
    this.notifications_for_rhino = [];
    this.notifications_diamonds = [];
    this.organization_subscriptions = [];
  }

}
