import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPixabayResult } from '../models/pixabay.interface';




@Injectable()
export class ToolsService {

	constructor(private http: HttpClient) {
	}

	getRandomImage(searchText: string): Observable<any> {
		const q = searchText.replace(' ', '+');
		const shapesKey = '5219034-ef0fdc8ec9c4ac715f33665a3';
		const url = 'https://pixabay.com/api/?key=' + shapesKey + '&q=' + q + '&image_type=photo';
		// https://pixabay.com/api/?key=5219034-ef0fdc8ec9c4ac715f33665a3&q=yellow+flower&image_type=photo
		const headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		return this.http.get(url)
			.pipe(
				map(res => {
					const resultat = <IPixabayResult>res;
					if (resultat.total === 0) {
						return false;
					}
					const imageId = Math.floor(Math.random() * resultat.hits.length);
					return resultat.hits[imageId].webformatURL;

				}));
	}

	getRandomImages(searchText: string): Observable<any> {
		const q = searchText.replace(' ', '+');
		const shapesKey = '5219034-ef0fdc8ec9c4ac715f33665a3';
		const url = 'https://pixabay.com/api/?key=' + shapesKey + '&q=' + q + '&image_type=photo';
		// https://pixabay.com/api/?key=5219034-ef0fdc8ec9c4ac715f33665a3&q=yellow+flower&image_type=photo
		const headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');

		return this.http.get(url)
			.pipe(
				map(res => {

					return res;
				}));
	}

}
