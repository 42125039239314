import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  onLoadingChange: Subject<boolean>;
  public loading = false;

  constructor() {
    this.onLoadingChange = new Subject();
  }

  setLoading(value: boolean) {
    this.loading = value;
    this.onLoadingChange.next(value);
  }
  start() {
    this.loading = true;
    this.onLoadingChange.next(true);
  }
  stop() {
    this.loading = false;
    this.onLoadingChange.next(false);
  }
}
