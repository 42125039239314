import { AfterViewInit, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PasswordTools } from '../../../shared/models/password.tools';
import { MessageService } from '../../../shared/services/message.service';
import { UserService } from '../../../shared/services/user.service';
;

@Component({
  selector: 'app-password-set',
  templateUrl: './password-set.component.html',
  styleUrls: ['./password-set.component.sass']
})
export class PasswordSetComponent implements AfterViewInit {
  step: number;
  showSpinner: boolean;
  forgotForm: UntypedFormGroup;
  token: string;
  email: string;
  passwordRegex: RegExp;
  checkLength: boolean;
  checkNumbersAndLetters: boolean;
  checkSpecialChars: boolean;
  checkAreTheSame: boolean;
  showPasswordChecks: boolean;
  puntuacio = 0;
  passwordStrongness = 0;

  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  tempPass: string;

  private colors = ['darkred', 'orangered', 'orange', 'yellowgreen'];




  constructor(private userService: UserService,
    private route: ActivatedRoute, public translate: TranslateService,
    private fb: UntypedFormBuilder, private messageService: MessageService,
    public snackBar: MatSnackBar,
    private router: Router) {
    this.step = 1;

    this.passwordRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!,@#$%^&*])(?=.{8,})'
    );
    this.showSpinner = false;

    this.checkLength = false;
    this.checkNumbersAndLetters = false;
    this.checkSpecialChars = false;


    this.route.params.forEach((params: Params) => {
      this.token = params['id2'];
      this.email = params['id'];
    }).then();
    this.forgotForm = this.fb.group({
      password: ['', [Validators.required,]],

    });

    this.forgotForm.controls['password'].valueChanges.subscribe(res => {
      this.tempPass = res;
      this.setBarColors(4, '#DDD');
      if (this.tempPass) {

        var cc = this.checkStrength(this.tempPass);
        const c = this.getColor(cc);
        this.passwordStrongness = cc;
        this.setBarColors(c.index, c.color);
      }


    });

  }


  ngAfterViewInit(): void {

  }

  goToMenu() {
    this.router.navigateByUrl('/').then();
  }
  onStrengthChanged($event) {

    this.puntuacio = $event;
  }

  passwordFormSubmit() {
    const password = PasswordTools.SHA1(this.forgotForm.get('password').value);

    this.showSpinner = true;
    this.userService.newPassword(this.email, this.token, password).subscribe(
      () => {
        this.showSpinner = false;
        this.messageService.showMessage("The Password has been changed");
        this.router.navigate(['/login']);
      },
      () => {
        this.showSpinner = false;
      },
      () => {
      }
    );
  }

  getColor(s) {
    let index = 0;
    if (s === 10) {
      index = 0;
    } else if (s === 20) {
      index = 1;
    } else if (s === 30) {
      index = 2;
    } else if (s === 40) {
      index = 3;
    } else {
      index = 4;
    }
    return {
      index: index + 1,
      color: this.colors[index]
    };
  }

  setBarColors(count, col) {
    for (let n = 0; n < count; n++) {
      this['bar' + n] = col;
    }
  }


  checkStrength(p) {
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    // 3
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = (p.length <= 6) ? Math.min(force, 10) : force;

    // 7
    force = (passedMatches === 1) ? Math.min(force, 10) : force;
    force = (passedMatches === 2) ? Math.min(force, 20) : force;
    force = (passedMatches === 3) ? Math.min(force, 30) : force;
    force = (passedMatches === 4) ? Math.min(force, 40) : force;

    return force;
  }

}
