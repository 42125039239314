import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'iishapes-password-reset-done',
  templateUrl: './password-reset-done.component.html',
  styleUrls: ['./password-reset-done.component.sass']
})
export class PasswordResetDoneComponent implements OnInit {


  email = '';
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.email = params['email'];
      }
      );
  }
}
