<div class="signContainer">
    <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
    <div class="container">
        <div class="row">
            <div class="col-md-12 logo"><img src="assets/img/Logo600x60.png"
                    style=" display:block; margin-left:auto; margin-right:auto" width="300px" /></div>
        </div>
        <div class="col-md-12  mb-3">
            <h1>{{'COMMON.SIGN_IN_TO_YOUR_ACCOUNT' | translate}} (Staff)</h1>
        </div>
        <form [formGroup]="FormLoginSuperuser" (ngSubmit)="login()" class="form-signin">

            <mat-form-field class="col-md-6 offset-md-3 mt-5">
                <input matInput type="email" [placeholder]="translate.instant('COMMON.EMAIL')"
                    formControlName="email" />
            </mat-form-field>

            <mat-form-field class="col-md-6 offset-md-3">
                <input matInput type="password" [placeholder]="translate.instant('COMMON.PASSWORD')"
                    formControlName="password" />
            </mat-form-field>

            <mat-form-field class="col-md-6 offset-md-3">
                <input matInput type="email" [placeholder]="translate.instant('ENTRY.SUPERUSER.EMAIL_USER')"
                    formControlName="user_email" />
            </mat-form-field>
            <div class="col-md-6 offset-md-3 mt-3">
                <button mat-raised-button class="whiteTextButton" type="submit" color="primary">
                    {{translate.instant('COMMON.CONTINUE')}}
                </button>
            </div>

        </form>
    </div>

</div>