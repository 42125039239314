
export interface Apps {
  name: string;
}


export class Member {
  public id: string;
  public avatar?: string;
  public fullname?: string;
  public email?: string;
  public jobtitle?: string;
  public about?: string;
  public phone?: string;

  public created_at: Date;
  public updated_at: Date;

  public supervisor: boolean;

  public permissions: string[];

  public inviting_email: string;
  public status: string;

  public pending_signup: boolean;

  public apps: Apps[];

  public product_suite_expired_at?: Date;
  public product_suite_license_type?: string;
  public product_suite_license_valid: boolean;

  public product_one_expired_at?: Date;
  public product_one_license_type?: string;
  public product_one_license_valid: boolean;

  constructor() {
    this.permissions = [];
    this.product_suite_license_valid = false;
    this.product_one_license_valid = false;
    this.pending_signup = false;
    this.apps = [];
    this.supervisor = false;

  }

  static create(jsonObject: any): Member {

    const o = new Member();
    o.id = jsonObject.id ? jsonObject.id : null;
    if (jsonObject.user) {

      o.email = jsonObject.user.email ? jsonObject.user.email : null;
      o.fullname = jsonObject.user.fullname ? jsonObject.user.fullname : null;
      o.avatar = jsonObject.user.avatar ? jsonObject.user.avatar : null;
      o.jobtitle = jsonObject.user.jobtitle ? jsonObject.user.jobtitle : null;
      o.phone = jsonObject.user.phone ? jsonObject.user.phone : null;
      o.about = jsonObject.user.about ? jsonObject.user.about : null;

      o.product_suite_expired_at = jsonObject.user.product_cloud_expired_at ? jsonObject.user.product_cloud_expired_at : null;
      o.product_suite_license_type = jsonObject.user.product_cloud_license_type ? jsonObject.user.product_cloud_license_type : null;
      o.product_one_expired_at = jsonObject.user.product_design_expired_at ? jsonObject.user.product_design_expired_at : null;
      o.product_one_license_type = jsonObject.user.product_design_license_type ? jsonObject.user.product_design_license_type : null;


      o.product_suite_license_valid = new Date(o.product_suite_expired_at).getTime() > Date.now();
      o.product_one_license_valid = new Date(o.product_one_expired_at).getTime() > Date.now();

    }

    if (!o.email) {
      o.email = jsonObject.inviting_email;
    }
    o.inviting_email = jsonObject.inviting_email ? jsonObject.inviting_email : null;
    o.pending_signup = jsonObject.user ? false : true;


    o.created_at = jsonObject.created_at ? jsonObject.created_at : null;
    o.updated_at = jsonObject.updated_at ? jsonObject.updated_at : null;
    o.status = jsonObject.status ? jsonObject.status : null;

    if (jsonObject.permissions) {
      for (const perm of jsonObject.permissions) {
        o.permissions.push(perm);
        if (perm == "ROLE_OWNER") {
          o.supervisor = true;
        }

        if (perm == "ROLE_RETAIL") {
          o.apps.push({ 'name': 'RETAIL' })
        }
        if (perm == "ROLE_ECOMMERCE") {
          o.apps.push({ 'name': 'ECOMMERCE' })
        }
        if (perm == "ROLE_CRM") {
          o.apps.push({ 'name': 'CRM' })
        }
        if (perm == "ROLE_CATALOGUE") {
          o.apps.push({ 'name': 'CATALOGUE' })
        }
        if (perm == "ROLE_PRICING") {
          o.apps.push({ 'name': 'PRICING' })
        }

        if (perm == "ROLE_FACTORY") {
          o.apps.push({ 'name': 'FACTORY' })
        }

        if (perm == "ROLE_DESIGN") {
          o.apps.push({ 'name': 'DESIGN & RHINO' })
        }
        if (perm == "ROLE_DIAMONDS") {
          o.apps.push({ 'name': 'DIAMONDS' })
        }
        if (perm == "ROLE_SUPPLIER") {
          o.apps.push({ 'name': 'SUPPLIER' })
        }
        if (perm == "ROLE_DEVELOPER") {
          o.apps.push({ 'name': 'DEVELOPER' })
        }

      }
    }

    return o;
  }

  get isStore() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_STORE');
  }
  get isSales() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_SALES');
  }
  get isBusiness() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_BUSINESS');
  }
  // get isCatalogue() {
  //   if (this.permissions.includes('ROLE_OWNER') === true) {
  //     return true;
  //   }
  //   return this.permissions.includes('ROLE_CATALOGUE');
  // }
  get isTask() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_TASK');
  }
  // get isDesign() {
  //   if (this.permissions.includes('ROLE_OWNER') === true) {
  //     return true;
  //   }
  //   return this.permissions.includes('ROLE_DESIGN');
  // }
  // get isPricing() {
  //   if (this.permissions.includes('ROLE_OWNER') === true) {
  //     return true;
  //   }
  //   return this.permissions.includes('ROLE_PRICING');
  // }
  get isDiamond() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_DIAMOND');
  }
  get isBranding() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_BRANDING');
  }
  // get isEcommerce() {
  //   if (this.permissions.includes('ROLE_OWNER') === true) {
  //     return true;
  //   }
  //   return this.permissions.includes('ROLE_ECOMMERCE');
  // }
  get isAnalytics() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_ANALYTICS');
  }


  // NEW ROLS SYSTEM 
  get isOwner() {
    return this.permissions.includes('ROLE_OWNER');
  }
  get isSupervisor() {
    return this.permissions.includes('ROLE_SUPERVISOR') || this.permissions.includes('ROLE_OWNER');
  }

  get isDashboard() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_DASHBOARD');
  }
  get isRetail() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_RETAIL');
  }
  get isEcommerce() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_ECOMMERCE');
  }
  get isCRM() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_CRM');
  }
  get isCatalogue() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_CATALOGUE');
  }
  get isPricing() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_PRICING');
  }
  get isFactory() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_FACTORY');
  }
  get isDesign() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_DESIGN');
  }
  get isForRhino() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_FOR_RHINO');
  }
  get isDiamonds() {
    if (this.permissions.includes('ROLE_OWNER') === true) {
      return true;
    }
    return this.permissions.includes('ROLE_DIAMOND');
  }


}

export class AddMember {
  public email: string;
  public plan: string;
  public roles: string[];
  public comment: string;
  public productKey: string;

  constructor() {
    this.roles = [];
  }

  static create(jsonObject: any): AddMember {
    const o = new AddMember();

    o.email = jsonObject.user.email ? jsonObject.user.email : null;
    o.plan = jsonObject.user.plan ? jsonObject.user.plan : null;
    o.comment = jsonObject.user.comment ? jsonObject.user.comment : null;
    o.productKey = jsonObject.user.productKey ? jsonObject.user.productKey : null;
    return o;
  }
}

export class RolMember {
  public member_id: string;
  public permissions: string[];

  constructor() {
    this.permissions = [];
  }

  static create(jsonObject: any): RolMember {
    const o = new RolMember();
    o.member_id = jsonObject.member_id ? jsonObject.member_id : null;
    return o;
  }
}
export class PlanMember {
  public member_id: string;
  public plan: string;

  constructor() { }

  static create(jsonObject: any): PlanMember {
    const o = new PlanMember();
    o.member_id = jsonObject.member_id ? jsonObject.member_id : null;
    o.plan = jsonObject.plan ? jsonObject.plan : null;
    return o;
  }
}

export class SubscriptionMember {
  public member_id: string;

  static create(jsonObject: any): SubscriptionMember {
    const o = new SubscriptionMember();
    o.member_id = jsonObject.member_id ? jsonObject.member_id : null;
    return o;
  }
}
