import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user.interface';
import { DeveloperService } from '../../services/developer.service';
import { ListsService } from '../../services/lists.service';
import { MessageService } from '../../services/message.service';
import { MessageEditDialogComponent } from '../message-edit-dialog/message-edit-dialog.component';
import { YesNoDialogComponent } from '../yesno-dialog/yesno-dialog.component';

@Component({
  selector: 'iishapes-basic-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.sass']
})
export class ProfileFormComponent implements OnInit {

  @ViewChild('fileUpload', { static: true }) fileInput: ElementRef;

  @Input() loading: boolean;
  @Input() user?: User;

  @Output() loadingChange = new EventEmitter();
  @Output() userChanges = new EventEmitter<User>();
  @Output() closeDialog = new EventEmitter();

  userForm: UntypedFormGroup;

  showDeveloperArea = false;
  languages = [];
  showButtons = false;
  uploadProcess: any = [];
  progress: any;
  urlUploadAvatar = environment.apiUrl + '/v1/me/avatar';
  //  urlUploadAvatar = 'http://localhost:8000/v1/me/avatar';
  public uploader: FileUploader = new FileUploader({ url: this.urlUploadAvatar });

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private _ngZone: NgZone,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    public messageService: MessageService,
    private listsService: ListsService,
    private developerService: DeveloperService,
    private clipboard: Clipboard,
    private dialog: MatDialog
  ) {

    this.loading = false;
    this.languages = this.listsService.getLanguages();

    this.userForm = this.fb.group({
      fullname: ['', [Validators.required, Validators.minLength(3)]],
      phone: [''],
      jobtitle: [''],
      email: [''],
      about: [''],
      lang: [''],
      beta: [''],
      dark_mode: ['']
    });
  }

  ngOnInit() {

    if (this.user) {
      if (this.user.fullname) this.userForm.patchValue({ fullname: this.user.fullname });
      if (this.user.phone) this.userForm.patchValue({ phone: this.user.phone });
      if (this.user.jobtitle) this.userForm.patchValue({ jobtitle: this.user.jobtitle });
      if (this.user.email) this.userForm.patchValue({ email: this.user.email });
      if (this.user.about) this.userForm.patchValue({ about: this.user.about });
      if (this.user.lang) this.userForm.patchValue({ lang: this.user.lang });
      if (this.user.beta) this.userForm.patchValue({ beta: this.user.beta });
      if (this.user.dark_mode) this.userForm.patchValue({ dark_mode: this.user.dark_mode });
    }

    this.userForm.controls['dark_mode'].valueChanges.subscribe(res => {
      if (res) {
        document.documentElement.setAttribute('theme', 'dark');
      } else {
        document.documentElement.setAttribute('theme', 'light');

      }
    });


    this.developerService.init(this.user.apikey);

    if (this.user.developerApikey.length > 0) {
      this.showDeveloperArea = true;
    }
    // Uploader Avatar
    this.uploader = new FileUploader({
      url: this.urlUploadAvatar,
      method: 'POST',
      autoUpload: true,
      itemAlias: 'avatar',
      headers: [{ name: 'apikey', value: this.user.apikey }]
    });

    this.uploader.onBeforeUploadItem = item => {
      item.withCredentials = false;
    };

    this.uploader.onErrorItem = (_fileItem, _response, _status) => { };

    this.uploader.onProgressItem = (fileItem, progress) => {
      this._ngZone.run(() => {
        const name = fileItem._file.name;
        for (const p of this.uploadProcess) {
          if (p['name'] === name) {
            p['progress'] = progress;
          }
        }

        this.progress = progress;
      });
    };
    this.uploader.onCompleteItem = (fileItem, response, _status) => {
      this._ngZone.run(() => {
        const name = fileItem._file.name;
        for (const i in this.uploadProcess) {
          if (this.uploadProcess[i]['name'] === name) {
            this.uploadProcess.splice(i, 1);
            break;
          }
        }

        const jso = JSON.parse(response);
        this.user = User.create(jso);
      });
    };


  }

  save() {

    const info: { [id: string]: string } = {};

    if (this.userForm.get('fullname').touched) {
      info['fullname'] = this.userForm.get('fullname').value;
    }
    if (this.userForm.get('about').touched) info['about'] = this.userForm.get('about').value;
    if (this.userForm.get('jobtitle').touched) info['jobtitle'] = this.userForm.get('jobtitle').value;
    if (this.userForm.get('email').touched) info['email'] = this.userForm.get('email').value;
    if (this.userForm.get('phone').touched) info['phone'] = this.userForm.get('phone').value;
    if (this.userForm.get('lang').touched) info['lang'] = this.userForm.get('lang').value;
    if (this.userForm.get('beta').touched) info['beta'] = this.userForm.get('beta').value;
    if (this.userForm.get('dark_mode').touched) info['dark_mode'] = this.userForm.get('dark_mode').value;


    this.updateUser(info, this.user).subscribe(
      res => {

        this.userChanges.emit(res);
        this.user = res;
        if (this.user.dark_mode) {
          document.documentElement.setAttribute('theme', 'dark');
        }
        this.updateAppLanguage();

        this.messageService.showMessage(this.translate.instant('DIALOGS.PROFILE.PROFILE_UPDATED'))
        this.closeDialog.emit();
      },
      (error) => {
        this.showMessage(error['error']['error']);
        this.setLoading(false);

      });
  }


  setLoading(b: boolean) {
    this.loading = b;
    this.loadingChange.emit(this.loading);
  }

  onAvatarClick() {
    this.fileInput.nativeElement.click();
  }

  deleteAvatar() {
    this.deleteAvatarUser().subscribe(res => {
      this.user = res;
    });
  }

  deleteAvatarUser(): Observable<User> {
    const url = environment.apiUrl + '/v1/me/avatar';
    const headers = new HttpHeaders().append('apikey', this.user.apikey);

    return this.http.delete(url, { headers }).pipe(
      map(res => {
        return User.create(res);
      })
    );
  }

  showMessage(message: string, action = '') {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  getSubscriptionInfo() {
    let date = this.datePipe.transform(this.user.product_cloud_expired_at, 'shortDate');
    if (this.user.isCloudLicenseValid() == true) {
      return this.translate.instant('COMMON.SUITE_WILL_EXPIRE', { 'date': date });
    }

    date = this.datePipe.transform(this.user.product_design_expired_at, 'shortDate');
    if (this.user.isDesignLicenseValid() == true) {
      return this.translate.instant('COMMON.SUITE_WILL_EXPIRE', { 'date': date });;
    }

    return 'No valid subscription';

  }

  updateUser(info: any, user: User): Observable<User> {
    const url = environment.apiUrl + '/v1/me/edit';
    // headers
    const headers = new HttpHeaders()
      .append('apikey', this.user.apikey);
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(info));
    return this.http.post(url, formData, {
      headers: headers
    }).pipe(
      map(res => {


        this.user = User.update(res, user);
        return this.user;
      }));
  }


  updateAppLanguage() {
    switch (this.user.lang) {
      case '1027':
        this.translate.use('ca');
        break;
      case '1034':
        this.translate.use('es');
        break;
      case '1036':
        this.translate.use('fr');
        break;
      case '1040':
        this.translate.use('it');
        break;
      case '1041':
        this.translate.use('ja');
        break;
      case '1042':
        this.translate.use('ko');
        break;
      case '1028':
        this.translate.use('zhcht');
        break;
      case '2052':
        this.translate.use('zhchs');
        break;
      case '2070':
        this.translate.use('pt');
        break;
      case '2073':
        this.translate.use('ru');
        break;
      case '1037':
        this.translate.use('he');
        break;
      case '1066':
        this.translate.use('vi');
        break;
      case '1054':
        this.translate.use('th');
        break;
      case '1025':
        this.translate.use('ar');
        break;
      case '1031':
        this.translate.use('de');
        break;
      case '1033':
        this.translate.use('en');
        break;
      case '1035':
        this.translate.use('fi');
        break;
      case '1038':
        this.translate.use('hu');
        break;
      case '1086':
        this.translate.use('ms');
        break;
      case '1044':
        this.translate.use('nb');
        break;
      case '1053':
        this.translate.use('sv');
        break;
      case '1032':
        this.translate.use('gr');
        break;
      default:
        this.translate.use('en');
        break;
    }
  }

  addApikey() {
    const dialogRef = this.dialog.open(MessageEditDialogComponent, {
      width: '400px',
      data: {
        title: 'COMMON.DEVELOPER',
        message: 'COMMON.ADD_NEW_APIKEY'
      },
      panelClass: 'custom-dialog-container'

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.developerService.addApikey(result).subscribe(res => {
          this.user?.developerApikey?.push(res);
          this.userChanges.emit(this.user);
          this.messageService.showMessage("New apikey has been create and copied to clipboard");
          this.clipboard.copy(res.apikey);
        });
      }
    });



  }
  copy(apikey: string) {
    this.messageService.showMessage("Apikey copied to clipboard");
    this.clipboard.copy(apikey);
  }

  deleteApikeyById(id: string) {

    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        title: "Delete",
        message: "Are you sure to delete the apikey?"
      },
      panelClass: 'custom-dialog-container'

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        this.developerService.deleteApikey(id).subscribe(() => {
          this.user.developerApikey = this.user.developerApikey.filter(a => a.id != id);
          this.userChanges.emit(this.user);

        });

      }
    });

  }
}
