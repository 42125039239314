import { UpperCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../shared/services/user.service';
;

@Component({
  selector: 'app-password-reset',
  templateUrl: 'password-reset.component.html',
  styleUrls: ['password-reset.component.sass'],
  providers: [UpperCasePipe, TranslatePipe]
})
export class PasswordResetComponent implements OnInit {

  forgotForm: UntypedFormGroup;
  step: number;
  serverError: string;
  serverInfo: string;
  showMessage: boolean;
  showSpinner: boolean;

  constructor(private userService: UserService,
    private fb: UntypedFormBuilder,
    private router: Router,
    public translate: TranslateService,
  ) {

    this.serverError = '';
    this.serverInfo = '';
    this.showSpinner = false;
    this.showMessage = false;
    this.step = 1;
  }

  ngOnInit() {
    this.forgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  forgotFormSubmit() {

    const email = this.forgotForm.get('email').value;

    this.serverError = '';
    this.serverInfo = '';
    this.showSpinner = true;

    this.userService.forgot(email).subscribe(
      () => {
        this.router.navigate(['/password/reset/done'], { queryParams: { email: email } }).then();
      },
      () => {
        this.showSpinner = false;
      },
      () => {
      }
    );
  }

  backToLogin() {
    this.router.navigateByUrl('/').then();
  }

}
