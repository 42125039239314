import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'iishapes-shared-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.sass']
})
export class IIShapesSharedForbiddenComponent implements OnInit {

  constructor(public router: Router,
    private userService: UserService,
              public translate: TranslateService) { }

  ngOnInit() {
  }

  goBack() {
    this.router.navigate(['/']).then();
  }
  logout() {
    this.userService.logout();
    this.router.navigate(['/']);
  }
}
