<div class="signContainer">
    <div class="are">
        <div class="left">
            <div class="title">{{'COMMON.PLEASE_VERIFY_YOUR_EMAIL' | translate}}</div>

            <div class="message">
                {{'COMMON.PLEASE_VERIFY_YOUR_EMAIL' | translate}} {{ 'COMMON.CHECK_EMAIL_TO_VERIFY' | translate : {
                value1: email} }}
            </div>
            <div class="message-bold">
                {{'COMMON.IF_YOU_DO_NOT_FIND_THE_EMAIL_IN_YOUR_INBOX' | translate}}
            </div>
            <div class="items">{{'COMMON.NEED_HELP' | translate}} <a target="_blank"
                    href="https://www.2shapes.com/contactus">
                    {{'COMMON.CONTACT_US' | translate}}</a></div>
        </div>

        <div class="right background-tint">
            <div class="top">
                <img src="assets/img/logow.png" width="150" height="32" />
            </div>
            <div class="texte">
                Design software for custom-made jewelry
            </div>
            <div class="bottom">
            </div>
        </div>
    </div>




</div>